import { formatCurrency } from "@internal/utils/currency";

import { InvitedDeal } from "./columns";

export function sortInvitedDeals(deals: InvitedDeal[] | undefined) {
  if (!deals || !deals.length) return [];

  const commRequired = deals.filter(
    (deal) => deal.investment?.status === "COMMITMENT_REQUIRED"
  );

  const paymentRequired = deals.filter(
    (deal) => deal.investment?.status === "PAYMENT_REQUIRED"
  );

  const paymentReceived = deals.filter(
    (deal) => deal.investment?.status === "PAYMENT_RECEIVED"
  );

  const transferInitiated = deals.filter(
    (deal) => deal.investment?.status === "TRANSFER_INITIATED"
  );

  return [
    ...commRequired,
    ...paymentRequired,
    ...paymentReceived,
    ...transferInitiated,
  ];
}

export function prefillEmptyCommitments(deal: InvitedDeal) {
  if (deal.investment && !deal.investment?.commitment) {
    const currency = deal.terms?.baseCurrency || "GBP";
    return {
      ...deal,
      investment: {
        ...deal.investment,
        commitment: {
          __typename: "MonetaryValue",
          formatted: formatCurrency({
            baseCurrency: currency,
            num: 0,
            minimumFractionDigits: 2,
          }),
          amount: 0,
        },
      },
    } satisfies InvitedDeal;
  }

  return deal;
}
