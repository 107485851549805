import { css } from "@@panda/css";
import { Box } from "@@panda/jsx";
import { ResultOf } from "@internal/gql/client";
import { createColumnHelper } from "@tanstack/react-table";
import { Typography } from "components/display/Typography/Typography";
import { StatusPill } from "components/main/StatusPill";
import { formatInstrumentTypename } from "screens/deals/utils";
import { formatIsoDate } from "utility/dates";

import { CompanyCell } from "../CompanyCell/CompanyCell";
import { LeadCell } from "../LeadCell/LeadCell";
import { passedDealsQuery } from "./usePassedDeals";

export enum Column {
  Company = "company",
  Status = "status",
  Empty = "empty",
  Lead = "lead",
  ClosedDate = "close-date",
}

type PassedDeal = ResultOf<typeof passedDealsQuery>["passedDeals"][number];

const columnHelper = createColumnHelper<PassedDeal>();

export const columns = [
  columnHelper.accessor("tradeName", {
    id: Column.Company,
    header: "Company",
    enableSorting: true,
    cell: (props) => (
      <CompanyCell
        name={props.row.original.tradeName}
        logo={props.row.original.logoUrl}
        type={formatInstrumentTypename(
          props.row.original.instrument.__typename
        )}
      />
    ),
  }),
  columnHelper.display({
    id: Column.Status,
    header: "Status",
    enableSorting: false,
    cell: (props) => (
      <Box
        w="full"
        display="flex"
        flexDir="row"
        alignItems="center"
        justifyContent={{ base: "flex-end", lg: "flex-start" }}
      >
        <StatusPill
          status={props.row.original.status}
          className={css({ w: "auto" })}
        />
      </Box>
    ),
  }),
  columnHelper.display({
    id: Column.Empty,
    header: "",
    enableSorting: false,
    cell: () => null,
  }),
  columnHelper.accessor("lead.displayName", {
    id: Column.Lead,
    header: "Lead",
    enableSorting: true,
    cell: (props) => <LeadCell name={props.row.original.lead.displayName} />,
  }),
  columnHelper.accessor("targetCloseDate", {
    id: Column.ClosedDate,
    header: "Close date",
    enableSorting: false,
    cell: (props) => {
      const date = props.row.original.targetCloseDate;
      return (
        <Typography variant="body" color="grey.gunsmoke">
          {date ? formatIsoDate(date) : "–"}
        </Typography>
      );
    },
  }),
];
