import { css, cx } from "@@panda/css";
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { StatusBanner } from "components/feedback/StatusBanner";
import { Skeleton } from "components/main/Skeleton";
import { useState } from "react";
import { getDealUrl } from "screens/deals/utils";

import { EmptyDealsView } from "../EmptyDealsView/EmptyDealsView";
import { TableCell } from "../TableHelpers/TableCell";
import { TableHeader } from "../TableHelpers/TableHeader";
import { tableStyles } from "../TableHelpers/styles";
import { Column, columns } from "./columns";
import { usePortfolioDeals } from "./usePortfolioDeals";
import { computeAccountAdminOfDeal } from "./utils";

export function PortfolioDeals() {
  const { data: deals, error, ready, accountId } = usePortfolioDeals();
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    columns,
    data: deals,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (error) {
    return (
      <StatusBanner
        error={{
          message: "We had a problem displaying your deals",
          error: "We had a problem displaying your deals",
          correlation_id: "graphql_portfolio",
        }}
      />
    );
  }

  if (ready && !deals.length) {
    return (
      <EmptyDealsView
        title="There’s nothing here"
        subText="Deals will appear here after your first investment"
      />
    );
  }

  return (
    <>
      <table className={css({ w: "full" })}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, i) => (
                <TableHeader
                  isFirst={i === 0}
                  onClick={header.column.getToggleSortingHandler()}
                  sorting={header.column.getIsSorted()}
                  canSort={header.column.getCanSort()}
                  key={header.id}
                  hiddenSm={i > 1}
                  alignRight={i > 1}
                  className={
                    header.id === Column.FinalInv
                      ? css({
                          textAlign: { base: "right", lg: "left" },
                        })
                      : undefined
                  }
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </TableHeader>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className={css({ w: "full" })}>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                key={row.id}
                data-testid={row.original.id}
                data-deal-id={row.original.id}
                className={cx(
                  tableStyles.row,
                  css({
                    _hover: {
                      '&:hover [data-company-name="true"]': {
                        borderBottomColor: "white",
                      },
                    },
                  })
                )}
              >
                {row.getVisibleCells().map((cell, i) => {
                  const isAdmin = computeAccountAdminOfDeal(
                    accountId,
                    row.original
                  );

                  const dealUrl = getDealUrl({
                    id: row.original.id,
                    dealSlug: row.original.slug ?? "",
                    syndSlug: row.original.syndicate?.slug ?? "",
                    type: isAdmin ? "admin" : "invest",
                  });

                  return (
                    <TableCell
                      key={cell.id}
                      dealUrl={dealUrl}
                      dealLinkText={`Go to ${row.original.tradeName}`}
                      testId={`${row.original.id}-${
                        // SafeCast: this maps to `Column` in our column definitions
                        cell.column.id as unknown as Column
                      }`}
                      hiddenSm={i > 1}
                      alignRight={i > 1}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!ready ? (
        <Skeleton
          count={5}
          className={css({
            height: "2.5rem",
            w: "full",
          })}
          containerClassName={css({
            display: "flex",
            flexDir: "column",
            gap: "xs",
            alignItems: "flex-start",
            pt: "l",
            w: "full",
          })}
        />
      ) : null}
    </>
  );
}
