import { HStack, VStack } from "@@panda/jsx";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { Typography } from "components/display/Typography/Typography";
import { StatusBanner } from "components/feedback/StatusBanner";
import { Button } from "components/main/Button";
import { Dialog } from "components/main/Dialog";
import { ComponentProps, ReactNode } from "react";

interface Props {
  files: { label: string; url?: string | undefined | null }[];
  triggerSlot?: ReactNode;
  isOpen?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
  error?: ComponentProps<typeof StatusBanner>["error"];
}
export function FilesModal({
  files,
  triggerSlot,
  isOpen,
  onClose,
  isLoading,
  error,
}: Props) {
  return (
    <Dialog
      title="Documents"
      forceOpen={!!isOpen}
      onClose={onClose}
      styling={{ width: "30rem" }}
    >
      {triggerSlot && <Dialog.Trigger>{triggerSlot}</Dialog.Trigger>}
      <Dialog.Content>
        {isLoading || error ? (
          <StatusBanner error={error} />
        ) : (
          <VStack gap="0" w="full">
            {files
              .filter(({ url }) => Boolean(url))
              .map(({ label, url }) => (
                <HStack
                  key={url}
                  justifyContent="space-between"
                  alignItems="center"
                  w="full"
                  py="m"
                >
                  <Typography variant="body">{label}</Typography>
                  <Button
                    variant="secondary"
                    as="a"
                    href={url ?? ""}
                    target="_blank"
                    mt="s"
                    aria-label={`Download ${label}`}
                    testId={`${label} link`}
                    borderRadius="full"
                    px="0!"
                    w="2rem!"
                    h="2rem!"
                    m="0"
                  >
                    <ArrowDownTrayIcon color="white" width="1rem" />
                  </Button>
                </HStack>
              ))}
          </VStack>
        )}
      </Dialog.Content>
    </Dialog>
  );
}
