import { RecipeVariant, sva } from "@@panda/css";

export const pillRecipe = sva({
  slots: ["container", "text", "icon"],
  base: {
    container: {
      py: "0.25rem",
      px: "0",
      flexShrink: "0",
      gap: "s",
    },
    text: {
      fontStyle: "normal",
      fontWeight: "medium",
    },
    icon: {
      width: "0.5rem",
      height: "0.5rem",
      borderRadius: "full",
    },
  },
  variants: {
    status: {
      purple: {
        icon: {
          background: "purple.fauve",
        },
        text: {
          color: "purple.fauve",
        },
      },
      green: {
        icon: {
          background: "green.moss",
        },
        text: {
          color: "green.moss",
        },
      },
      yellow: {
        icon: {
          background: "amber.yellowsea",
        },
        text: {
          color: "amber.yellowsea",
        },
      },
      grey: {
        icon: {
          background: "grey.iron",
        },
        text: {
          color: "grey.iron",
        },
      },
      orange: {
        icon: {
          background: "brand.yamcha",
        },
        text: {
          color: "brand.yamcha",
        },
      },
    },
  },
});

export type PillVariants = RecipeVariant<typeof pillRecipe>;
