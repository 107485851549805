import { graphql, useGraphql } from "@internal/gql/client";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { seconds } from "utility/seconds";

import { prefillDraftWithoutNameOrAmount, sortCreatedDeals } from "./helpers";

export function useCreatedDeals() {
  const router = useRouter();
  const { data, isSuccess, error } = useGraphql(createdDealsQuery, {
    options: {
      staleTime: seconds(5),
    },
  });

  // https://tanstack.com/table/latest/docs/guide/data#give-data-a-stable-reference
  const createdDeals = useMemo(() => {
    return sortCreatedDeals(data?.createdDeals).map(
      prefillDraftWithoutNameOrAmount
    );
  }, [data]);

  return {
    ready: isSuccess && router.isReady,
    error,
    data: createdDeals,
  };
}

export const createdDealsQuery = graphql(`
  query GetCreatedDeals {
    createdDeals {
      __typename
      ... on Deal {
        __typename
        id
        createdAt
        tradeName
        targetCloseDate
        logoUrl
        status
        slug

        ... on Primary {
          instrument {
            __typename
          }
        }
        ... on Secondary {
          instrument {
            __typename
          }
        }

        syndicate {
          __typename
          id
          slug
        }
        terms {
          __typename
          allocation {
            __typename
            formatted
          }
        }
        investmentSummary {
          __typename
          investorsInvested
          investorsTotal
          currentCalculations {
            __typename
            totalReceived {
              __typename
              formatted
              amount
            }
          }
        }
      }
    }
  }
`);
